import { Outlet, useLocation } from "react-router-dom";
import Header from "./components/Header";
import { Container } from "react-bootstrap";
import { Toaster } from "react-hot-toast";

function App() {
  const { pathname } = useLocation();
  return (
    <div>
      {pathname !== '/login' && <Header />}
      <Container>
        <Outlet />
      </Container>
      <Toaster />
    </div>
  );
}

export default App;
