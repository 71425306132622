import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';

const Header = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        signOut(auth).then(() => {
            navigate('/login');
            localStorage.removeItem('user');
            console.log('Çıkış Yapıldı');
        }).catch((error) => {
            console.error(error);
        });
    };

    return (
        <Navbar>
            <Container>
                <Navbar.Brand href="/">Defterim</Navbar.Brand>
                <Nav>
                    <Nav.Link href="/">İşlemler</Nav.Link>
                    <Nav.Link href="/orders">Siparişler</Nav.Link>
                </Nav>
                <Nav className="ml-auto">
                    <Nav.Link onClick={handleLogout}>Çıkış Yap</Nav.Link>
                </Nav>
            </Container>
        </Navbar>
    )
}
export default Header