import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import PrivateRoute from "./components/PrivateRoute";
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

const OperationPage = React.lazy(() => import('./pages/OperationPage'));
const LoginPage = React.lazy(() => import('./pages/LoginPage'));
const OrderPage = React.lazy(() => import('./pages/OrderPage'));

const router = createBrowserRouter(createRoutesFromElements(
  <Route path="/" element={<App />}>
    <Route index={true} path="/login" element={<Suspense fallback={<Spinner />}><LoginPage /></Suspense>} />
    <Route path="" element={<Suspense fallback={<Spinner />}><PrivateRoute /></Suspense>}>
      <Route path="/" element={<Suspense fallback={<Spinner />}><OperationPage /></Suspense>} />
      <Route path="/orders" element={<Suspense fallback={<Spinner />}><OrderPage /></Suspense>} />
    </Route>
  </Route>
));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
